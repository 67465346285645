const Trailer = () => (
    <section className="trailer-section">
        <h2>Watch The Trailer</h2>
        <iframe src="https://player.vimeo.com/video/702257874?h=6eccc1e88f&byline=0&portrait=0"
        id="trailer"
        title="NYU 2nd Year Showcase Trailer" width="1080" height="608"
        allow="fullscreen" allowFullScreen></iframe>
    </section>
);

export default Trailer;